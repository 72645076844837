import React, { Component, Fragment } from 'react';
import loadable from '@loadable/component';

const TopBar = loadable(() => import('common/blocks/topBar'));
const Header = loadable(() => import('common/blocks/header'));
const Footer = loadable(() => import('common/blocks/footer'));
const AgeVerificationModal = loadable(() => import('common/blocks/ageVerificationModal/'));
const Copyright = loadable(() => import('common/blocks/copyright'));
const CookieConsent = loadable(() =>
  import('common/blocks/functional/cookieConsent')
);
const RichSnippet = loadable(() =>
  import('common/components/_partials/RichSnippet')
);

class DefaultLayout extends Component {
  render() {
    const { children } = this.props;
    return (
      <Fragment>
        <TopBar />
        <Header />
        <main data-id="content">{children}</main>
        <Footer />
        <Copyright />
        <CookieConsent />
        <AgeVerificationModal />
        <RichSnippet socials={[]} />
        <RichSnippet type={'search'} />
      </Fragment>
    );
  }
}

export default DefaultLayout;
